module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"<%= title %>","short_name":"<%= slug %>","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"/codebuild/output/src1258741284/src/mal/src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6215fbe129d0aa49bc1762e2cc3466ea"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
